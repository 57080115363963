import React from "react";
import TopDarkModePage from './TopDarkModePage';


const TopDark = () => (
<>
        <TopDarkModePage />
</>
)

export default TopDark