import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

import TopDark from "../components/Modules/Top/TopDarkPage"
import Footer from "../components/Modules/FooterPage"

export default function Page({ data }) {
    const page = data.allWpPage.nodes[0]
    const postContent = data.allWpPage.nodes.map(({content}) => (<div dangerouslySetInnerHTML={{ __html: content }} />))
      //SEO THINGS
    const SeoTitle = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.title))
    const SeoDescription = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.description)) 
    const SeoKeywords = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.keywords))
      //END SEO THINGS

  return (
      <>
        <Seo 
          title = {SeoTitle}
          description = {SeoDescription}
          keywords = {SeoKeywords.toString()}
        />
        <TopDark />
        <div className="refeng-page-container">
            <h1 style={{display: "grid", justifyContent: "center"}}>{page.title}</h1>
            <div>{postContent}</div>
        </div>
       <div><Footer /></div>
      </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        title
        content
        seoqueries {
          description
          keywords
          title
        }
      }
    }
  }
`