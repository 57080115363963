import React from "react";
import {Link} from "gatsby";
import {Fade} from 'react-reveal';

import {Container, Row, Col} from 'react-bootstrap'


import LogoDark from "../../../Elements/Logos/RefLogos/Reflogoblue"
import Phone from "../../../Elements/Phone/PhoneBlack"
import NavDark from '../../NavMenu/NavMenuBlack'

export default function TopDarkMode() {
    return(
        <>
        <Container fluid className="top-page">
            <Row>
                <Col className="logo" xl={2} lg={2} md={3} sm={3} xs={4}>
                    <Fade duration={3000}>
                        <Link to="/"><LogoDark /></Link>
                    </Fade>
                </Col>
                <Col xl={6} lg={6}  md={3} sm={3} xs={7} className="top_empty"></Col>
                <Col className="top-element" xl={2} lg={2}  md={4} sm={4} xs={6}>
                    <Fade duration={3000}>
                        <Phone />
                    </Fade>
                </Col>
                <Col sm={2} xs={2} className="nav-menu-button">
                    <NavDark />
                </Col>
            </Row>
            {/* <Row className="phone-adaptive">
                <Col className="top-element-adaptive" xs={12}>
                    <Fade duration={3000}>
                        <Phone />
                    </Fade>
                </Col>
            </Row> */}
        </Container>
        </>
    )
}

